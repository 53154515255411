import React from 'react'

// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     useParams,
// } from "react-router-dom";
// import {Navbar, Nav, NavDropdown, Form, FormControl, Button, Container} from 'react-bootstrap'
// import Home from './Home';
// import AboutUs from './AboutUs';
// import ContactUs from './ContactUs';
class BootstrapNavbar extends React.Component{
    render(){
        return(
            <nav className="navbar sticky-top navbar-expand-lg navbar-dark navbar-sevasoft">
                <div className="container">
                    <a className="navbar-brand" href="#home">
                        <img src="/assets/img/logo-60x34.gif" alt="SEVASOFT Logo" title="Software Atelier" width="60" height="34"/>
                        &nbsp;&nbsp;</a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarMain"
                        aria-controls="basic-navbar-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon navbar-sevasoft-toggler-icon"></span>
                    </button>

            <div className="collapse navbar-collapse justify-content-end" id="navbarMain">
            <ul className="navbar-nav me-0 mb-2 mb-lg-0">
                <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="./#">SEVASOFT atelier</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#anchKontat">Kontakt</a>
                </li>
            </ul>
        </div>
            </div>
         </nav>

        )
    }
}

export default BootstrapNavbar;

