// import logo from './logo.svg';

import './App.css';
import * as React from 'react';
// import 'bootstrap/dist/css/bootstrap.css';

function App() {
    return (
        // <div className="row">
        <div className="App" id="home">
            <header className="App-header">
                <h1>Software atelier</h1>
                <h2>Java, Spring</h2>
            </header>
            <section className="App-section-slogan">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 ">
                            <div className="slogan-blok">
                                Jsme softwarový ateliér,
                                dílna softwarového vybavení. Od návrhu po uskutečnění.
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 ">
                            <div className="slogan-blok">
                                Jednoduché, rychlé, chytré a
                                usměvavé programy, aplikace, ucelená řešení.
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="App-section-slogan" id="anchKontakt">
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-md-6 col-lg-6 ">
                            <div className="slogan-blok">
                                Hostujeme a spravujeme aplikace. Účetní programy, webové aplikace, emailové služby.
                            </div>

                            <div className="slogan-blok">
                                <ul className="list-unstyled mb-md-4 mb-lg-0">
                                    <li className="lh-lg"><a
                                        className="text-800 text-decoration-none"
                                        href="https://api.whatsapp.com/send?phone=420777888003&text=M%C3%A1m%20z%C3%A1jem%20o%20va%C5%A1e%20slu%C5%BEby,%20spolupr%C3%A1ci"
                                        target="whatsapp_sevasoft" title="Napište na WhatsApp">WhatsApp</a>
                                    </li>
                                    <li className="lh-lg"><a
                                        className="text-800 text-decoration-none"
                                        href="https://www.t.me/kareldusek" target="whatsapp_sevasoft"
                                        title="Napište na Telegram">Telegram</a></li>
                                    <li className="lh-lg"><a
                                        className="text-800 text-decoration-none" target="email_sevasoft"
                                        title="Zavolejte"
                                        href="tel:+420777888003">Tel&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+420 777 888 003</a></li>
                                    <li className="lh-lg"><a
                                        className="text-800 text-decoration-none"
                                        href="mailto:&#97;&#116;&#101;&#108;&#105;&#101;&#114;&#64;&#115;&#101;&#118;&#97;&#115;&#111;&#102;&#116;&#46;&#99;&#122;">Email
                                        &#97;&#116;&#101;&#108;&#105;&#101;&#114;&#64;&#115;&#101;&#118;&#97;&#115;&#111;&#102;&#116;&#46;&#99;&#122;</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 ">
                            <div className="slogan-blok">
                                <ul className="list-unstyled mb-md-4 mb-lg-0 ">
                                    <li className="lh-lg fw-bold">SEVASOFT, s.r.o.
                                    </li>
                                    <li className="lh-lg">Zakšínská 570/2,
                                        190 00 Praha 9
                                    </li>
                                    <li className="lh-lg">IČO: 28440960  DIČ: CZ28440960</li>
                                    <li className="lh-lg">Zapsán u Městského soudu v Praze
                                        <br/>dne: 5.8.2008, Zn.: C/141702</li>
                                    <li className="lh-lg">Jsme plátci DPH.</li>
                                    <li className="lh-lg fw-bold">
                                        Bankovní spojení</li>
                                    <li className="lh-lg">
                                        Číslo účtu: 2800027702/2010</li>
                                    <li className="lh-lg">
                                    Majitel: SEVASOFT, s.r.o.
                                    </li>
                                    <li className="lh-lg">
                                    IBAN: CZ5920100000002800027702
                                    </li>
                                    <li className="lh-lg">
                                    BIC: FIOBCZPPXXX
                                    </li>
                                    <li className="lh-lg">
                                    Fio banka, a.s., V Celnici 1028/10, Praha 1
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        // </div>
    );
}

export default App;
